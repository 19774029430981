import React from 'react'

function RegistrationDetails(props) {
  const {
    registrant
  } = props

  if (registrant === undefined) return (
    <p>No data found.</p>
  )

  const firstLetterCap = string => {
    if (!string) return

    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  return (
    <div style={{ textAlign: 'left' }}>
      <div style={{ padding: 15, background: '#f2f2f2' }}>
        <div>{registrant.first_name} {registrant.middle_initial} {registrant.last_name}</div>
        <div style={{ height: 3 }} />
        <div>{registrant.age} years old, {firstLetterCap(registrant.gender)}</div>
        <div style={{ height: 3 }} />
        <div>{registrant.contact_no}</div>
        <div style={{ height: 3 }} />
        <div>{registrant.email}</div>
        <div style={{ height: 3 }} />
        <div>Mode of Transportation: {firstLetterCap(registrant.transport)}</div>
        {//<p>Ministry: {firstLetterCap(registrant.ministry)}</p>
        }
        <p>
          In case of Emergency please notify {registrant.ice_fullname},
          the {registrant.ice_relationship}, through {registrant.ice_contact_no}.
        </p>
        <p>
          Medical conditions: {registrant.medical_conditions}
        </p>
      </div>
    </div>
  )
}

export default RegistrationDetails
