import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Select from '@material-ui/core/Select'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/styles'

import ButtonSpinner from 'components/ButtonSpinner'

function RegistrationForm(props) {
  const {
    onComplete
  } = props

  const theme = useTheme()
  const smView = useMediaQuery(theme.breakpoints.down('sm'))

  const [lastName, setLastName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [middleInitial, setMiddleInitial] = useState('')
  const [nickname, setNickname] = useState('')
  const [gender, setGender] = useState('Male')
  const [age, setAge] = useState('')
  const [civilStatus, setCivilStatus] = useState('Single')
  const [contactNo, setContactNo] = useState('')
  const [facebookId, setFacebookId] = useState('')
  const [email, setEmail] = useState('')
  const [transport, setTransport] = useState(`Recharge Bus`)
  // const [ministry, setMinistry] = useState('Intune')
  const [iceFullname, setIceFullname] = useState('')
  const [iceRelationship, setIceRelationship] = useState('')
  const [iceContactNo, setIceContactNo] = useState('')
  const [medicalConditions, setMedicalConditions] = useState('None')

  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)

  useEffect(() => {
    if (lastName
      && firstName
      && nickname
      && gender
      && age
      && contactNo
      && email
      && transport
      // && ministry
      && iceFullname
      && iceRelationship
      && iceContactNo
      && medicalConditions) {
      setComplete(true)
    } else {
      setComplete(false)
    }
  }, [
    lastName,
    firstName,
    nickname,
    gender,
    age,
    contactNo,
    email,
    transport,
    // ministry,
    iceFullname,
    iceRelationship,
    iceContactNo,
    medicalConditions
  ])

  const handleComplete = () => {
    setLoading(true)

    onComplete({
      lastName,
      firstName,
      middleInitial,
      nickname,
      gender,
      age,
      civilStatus,
      contactNo,
      facebookId,
      email,
      transport,
      // ministry,
      iceFullname,
      iceRelationship,
      iceContactNo,
      medicalConditions
    }, () => setLoading(false))
  }

  return (
    <>  
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            label="Last Name"
            required
            value={lastName}
            onChange={e => setLastName(e.target.value)}
            InputLabelProps={{
              shrink: true,
              style: {
                fontFamily: 'inherit'
              }
            }}
            InputProps={{
              style: {
                fontFamily: 'inherit'
              }
            }}
            fullWidth
            margin="normal"
          />
          <TextField
            variant="outlined"
            label="First Name"
            required
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
            InputLabelProps={{
              shrink: true,
              style: {
                fontFamily: 'inherit'
              }
            }}
            InputProps={{
              style: {
                fontFamily: 'inherit'
              }
            }}
            fullWidth
            margin="normal"
          />
          <TextField
            variant="outlined"
            label="Middle Initial"
            value={middleInitial}
            onChange={e => setMiddleInitial(e.target.value)}
            InputLabelProps={{
              shrink: true,
              style: {
                fontFamily: 'inherit'
              }
            }}
            InputProps={{
              style: {
                fontFamily: 'inherit'
              }
            }}
            fullWidth
            margin="normal"
          />
          <Divider style={{ height: 20, background: 'unset' }} />
          <TextField
            variant="outlined"
            label="Nickname"
            required
            value={nickname}
            onChange={e => setNickname(e.target.value)}
            InputLabelProps={{
              shrink: true,
              style: {
                fontFamily: 'inherit'
              }
            }}
            InputProps={{
              style: {
                fontFamily: 'inherit'
              }
            }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            label="Age"
            type="number"
            required
            value={age}
            onChange={e => setAge(e.target.value)}
            InputLabelProps={{
              shrink: true,
              style: {
                fontFamily: 'inherit'
              }
            }}
            InputProps={{
              style: {
                fontFamily: 'inherit'
              },
              inputProps: {
                max: 100,
                min: 0
              }
            }}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel shrink>Gender</InputLabel>
            <Select
              native
              variant="outlined"
              fullWidth
              value={gender}
              input={<OutlinedInput notched labelWidth={55} />}
              onChange={e => setGender(e.target.value)}
            >
              <option value='Male'>Male</option>
              <option value='Female'>Female</option>
            </Select>
          </FormControl> 
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel shrink>Civil Status</InputLabel>
            <Select
              native
              fullWidth
              value={civilStatus}
              input={<OutlinedInput notched labelWidth={83} />}
              onChange={e => setCivilStatus(e.target.value)}
            >
              <option value='Single'>Single</option>
              <option value='Married'>Married</option>
              <option value='Widowed'>Widowed</option>
            </Select>
          </FormControl> 
          <Divider style={{ height: 20, background: 'unset' }} />
          <TextField
            variant="outlined"
            label="Contact Number"
            required
            value={contactNo}
            onChange={e => setContactNo(e.target.value)}
            InputLabelProps={{
              shrink: true,
              style: {
                fontFamily: 'inherit'
              }
            }}
            InputProps={{
              style: {
                fontFamily: 'inherit'
              }
            }}
            fullWidth
            margin="normal"
          />
          <TextField
            variant="outlined"
            label="Facebook Username"
            value={facebookId}
            onChange={e => setFacebookId(e.target.value)}
            InputLabelProps={{
              shrink: true,
              style: {
                fontFamily: 'inherit'
              }
            }}
            InputProps={{
              style: {
                fontFamily: 'inherit'
              }
            }}
            fullWidth
            margin="normal"
          />
          <TextField
            variant="outlined"
            label="Email Address"
            type="email"
            required
            value={email}
            onChange={e => setEmail(e.target.value)}
            InputLabelProps={{
              shrink: true,
              style: {
                fontFamily: 'inherit'
              }
            }}
            InputProps={{
              style: {
                fontFamily: 'inherit'
              }
            }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Divider style={{ height: 20, background: 'unset' }} />
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel shrink>Mode of Transportation</InputLabel>
            <Select
              native
              fullWidth
              value={transport}
              input={<OutlinedInput notched labelWidth={170} />}
              onChange={e => setTransport(e.target.value)}
            >
              <option value={`Recharge Bus`}>Recharge Bus</option>
              <option value={`Personal`}>Personal</option>
            </Select>
          </FormControl> 
          {
          /*
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel shrink>Ministry</InputLabel>
            <Select
              native
              fullWidth
              value={ministry}
              input={<OutlinedInput notched labelWidth={60} />}
              onChange={e => setMinistry(e.target.value)}
            >
              <option value={'Intune'}>Intune</option>
              <option value={'Production'}>Production</option>
              <option value={'Ushers'}>Ushers</option>
              <option value={'Connect'}>Connect</option>
              <option value={'Citichamps'}>Citichamps</option>
              <option value={'Storehouse'}>Storehouse</option>
              <option value={'Mactan Midweek'}>Mactan Midweek</option>
              <option value={'CBSI'}>CBSI</option>
              <option value={'Platinum'}>Platinum</option>
              <option value={'Overflow'}>Overflow</option>
              <option value={'Youth'}>Youth</option>
              <option value={'Fusion'}>Fusion</option>
              <option value='Still considering to join'>Still considering to join</option>
            </Select>
          </FormControl> 
          */
          }
        {
        /*
        </Grid>
        <Divider style={{ height: 30, background: 'unset' }} />
        <Grid item xs={12} sm={6} md={4} lg={3}>
        */
        }
          <Divider style={{ height: 20, background: 'unset' }} />
          <p style={{ textAlign: 'left' }}>In case of emergency, please notify:</p>
          <TextField
            variant="outlined"
            label="Full Name"
            required
            value={iceFullname}
            onChange={e => setIceFullname(e.target.value)}
            InputLabelProps={{
              shrink: true,
              style: {
                fontFamily: 'inherit'
              }
            }}
            InputProps={{
              style: {
                fontFamily: 'inherit'
              }
            }}
            fullWidth
            margin="normal"
            helperText={<em>Full name of the person to contact</em>}
          />
          <TextField
            variant="outlined"
            label="Relationship"
            required
            value={iceRelationship}
            onChange={e => setIceRelationship(e.target.value)}
            InputLabelProps={{
              shrink: true,
              style: {
                fontFamily: 'inherit'
              }
            }}
            InputProps={{
              style: {
                fontFamily: 'inherit'
              }
            }}
            fullWidth
            margin="normal"
          />
          <TextField
            variant="outlined"
            label="Contact Number"
            required
            value={iceContactNo}
            onChange={e => setIceContactNo(e.target.value)}
            InputLabelProps={{
              shrink: true,
              style: {
                fontFamily: 'inherit'
              }
            }}
            InputProps={{
              style: {
                fontFamily: 'inherit'
              }
            }}
            fullWidth
            margin="normal"
            helperText={<em>Contact number of the person to contact</em>}
          />
          <TextField
            variant="outlined"
            label="Medical Conditions"
            value={medicalConditions}
            onChange={e => setMedicalConditions(e.target.value)}
            InputLabelProps={{
              shrink: true,
              style: {
                fontFamily: 'inherit'
              }
            }}
            InputProps={{
              style: {
                fontFamily: 'inherit'
              }
            }}
            fullWidth
            margin="normal"
            helperText={<em>Your medical conditions</em>}
          />
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: 16 }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth={smView}
          style={!smView ? { marginLeft: 'auto' } : null}
          disabled={loading || !complete}
          onClick={handleComplete}
        >
          {loading ? <ButtonSpinner /> : null}
          COMPLETE
        </Button>
      </Grid>
    </>
  )
}

export default RegistrationForm
