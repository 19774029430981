import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Button from '@material-ui/core/Button'

import RegistrationDetails from 'scenes/Registration/RegistrationDetails'
import RegistrationFinder from 'scenes/Registration/RegistrationFinder'
import NameSearch from './NameSearch'
import NameSearchResults from './NameSearchResults'
 
function PaymentDialog(props) {
  const {
    onClose,
    selectedValue,
    open,
    receiver,
    onSuccessfulPayment = () => {}
  } = props

  const [registrant, setRegistrant] = useState()
  const [nameSearchMatches, setNameSearchMatches] = useState()
  const [expanded, setExpanded] = useState(true)
  const [amount, setAmount] = useState(0)
  const [receiptNo, setReceiptNo] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  const handleClose = () => {
    onClose(selectedValue)
    setNameSearchMatches(null)
  }

  const handleAddPayment = async () => {
    setLoading(true)
    
    let result = null

    // Check receipt number
    result = await fetch(`/.netlify/functions/token-hider?collection=payments&check=true&receipt_no=${receiptNo}`, {
      method: 'GET',
    })

    const receiptValidity = await result.json()
    
    console.log(receiptValidity)
    if (receiptValidity !== 'OK') {
      setError(`Receipt number error. [${receiptValidity}]`)
      setLoading(false)

      return 
    }
  
    result = await fetch('/.netlify/functions/token-hider?collection=payments', {
      method: 'POST',
      body: JSON.stringify({
        amount: amount,
        receipt_no: receiptNo,
        receiver: receiver,
        registrant: registrant,
      })
    })
    
    const payment = await result.json()

    setLoading(false)
    if (payment['$undefined']) {
      setError('Registration Failed. Server error occurred.')
    }
    
    console.log('Snackbar: success')
    onSuccessfulPayment()
    handleClose()
    setAmount(0)
    setReceiptNo('')
    setRegistrant(null)
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
    >
      <DialogTitle>Receive Payment</DialogTitle>
      <DialogContent>
        {!registrant
          ? (
          <>
            <small>Choose one from the options below.</small>
            <div style={{ height: 30 }} />
            <RegistrationFinder showUploadQR={false} callback={reg => setRegistrant(reg)} />
            <div style={{ height: 30 }} />
            <NameSearch callback={matches => setNameSearchMatches(matches)} />
            <div style={{ height: 10 }} />
            {nameSearchMatches !== null &&
              <NameSearchResults matches={nameSearchMatches} onItemClick={reg => setRegistrant(reg)} />
            }
          </>
          ) : (
          <>
            <Button
              variant="outlined"
              onClick={() => {
                setNameSearchMatches(null)
                setRegistrant(null)
              }}
              size="small"
            >
              Go Back
            </Button>
            <div style={{ height: 10 }} />
            <ExpansionPanel
              expanded={expanded}
              onChange={() => setExpanded(prevState => !prevState)}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <span style={{ fontSize: 18 }}>Registration Details</span>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ overflowX: 'auto' }}>
                <div>
                  <div>{registrant._id}</div>
                  <RegistrationDetails registrant={registrant} />
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <div>
              <h3>Payment</h3>
              {error && <p style={{ color: 'red' }}>{error}</p>}
              <TextField
                label="Amount"
                InputLabelProps={{
                  shrink: true,
                  style: {
                    fontFamily: 'inherit'
                  }
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">Php</InputAdornment>,
                }}
                fullWidth
                value={amount}
                type="number"
                align="right"
                onChange={e => {
                  setError('')
                  setAmount(e.target.value)
                }}
              />
              <div style={{ height: 20 }} />
              <TextField
                label="Receipt No."
                InputLabelProps={{
                  shrink: true,
                  style: {
                    fontFamily: 'inherit'
                  }
                }}
                fullWidth
                value={receiptNo}
                align="right"
                onChange={e => {
                  setError('')
                  setReceiptNo(e.target.value)
                }}
              />
              <div style={{ height: 20 }} />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading || !amount}
                onClick={handleAddPayment}
              >
                {loading ? <>PROCESSING...</> : <>ADD</>}
              </Button>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

PaymentDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  receiver: PropTypes.object.isRequired,
}

export default PaymentDialog
