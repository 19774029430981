import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import GoIcon from '@material-ui/icons/ArrowForward'

function NameSearch(props) {
  const {
    callback = () => {},
  } = props

  const [didSearch, setDidSearch] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [name, setName] = useState('')


  const handleGo = async (successCallback, name) => {
    setDidSearch(true)
    const result = await fetch(`/.netlify/functions/token-hider?collection=registrants&last_name=${name}`, {
      method: 'GET',
    })

    
    const matches = await result.json()

    if (!matches) {
      setNotFound(true)
      setDidSearch(false)
      return
    }
    
    setDidSearch(false)
    successCallback(matches)
  }

  return (
    <>
      <div>
        {didSearch ? 'Searching...' : null}
        {notFound ? 'Not Found' : null}
      </div>
      <TextField
        label="Last Name"
        InputLabelProps={{
          shrink: true,
          style: {
            fontFamily: 'inherit'
          }
        }}
        value={name}
        onChange={e => {
          setNotFound(false)
          setName(e.target.value.trim())
        }}
        onKeyPress={e => {
          if(e.key === 'Enter'){
            handleGo(callback, name)
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton disabled={!name} onClick={() => handleGo(callback, name)}>
                <GoIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  )
}

export default NameSearch
