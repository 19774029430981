import React from 'react'

import AppHeader from 'components/AppHeader'
import AppContent from 'components/AppContent'

function AppView() {
  return (
    <>
      <header
        style={{
          paddingTop: 20
        }}
      >
        <AppHeader />
      </header>
      <main>
        <AppContent />
      </main>
    </>
  )
}

export default AppView