import React from 'react'
import { Router } from 'react-router'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import AppView from './components/AppView'
import history from './history'
import appTheme from './theme'

const theme = createMuiTheme(appTheme)

function App() {
  return (
    <Router history={history}>
      <MuiThemeProvider theme={theme}>
        <AppView />
      </MuiThemeProvider>
    </Router>
  )
}

export default App