import React, { useState, useEffect } from 'react'
import canvg from 'canvg'
import QRCode from 'qrcode.react'
import Button from '@material-ui/core/Button'

import PaymentsTable from 'scenes/Receiver/PaymentsTable'
import RegistrationDetails from './RegistrationDetails'

function RegistrationStatus(props) {
  const {
    history,
    location
  } = props

  const [regImg, setRegImg] = useState()
  
  const { registrant } = location.state || {}

  useEffect(() => {
    let canvas = document.getElementById('canvas')
    const svgString = document.getElementById('svg-reg-img').outerHTML
    canvg(canvas, svgString)

    const image = canvas.toDataURL('image/jpeg')
    setRegImg(image)
    
    if (history.action === 'POP') {
      reFetch(registrant._id)
    }
  }, [])


  if (!location.state) return (
    <>
      <p>Something went wrong.</p>
      <Button
        variant="outlined"
        onClick={() =>
          history.push('/')
        }
      >
        RETURN TO HOME SCREEN
      </Button>
    </>
  )

  const reFetch = async code => {
    if (!code) return
    const result = await fetch(`/.netlify/functions/token-hider?collection=registrants&code=${code}`, {
      method: 'GET',
    })
    
    const registrant = await result.json()
    if (!registrant) return

    history.replace({ state: { registrant  } })
  }

  const totalBalance = registrant.total_balance.$numberDouble || registrant.total_balance.$numberLong

  return (
    <div style={{ margin: 'auto', textAlign: 'left', maxWidth: 700 }}>
      <div style={{ display: 'none' }}>
        <canvas id="canvas" width="250px" height="300px"/>
      </div> 
      <h1 style={{ textAlign: 'center' }}>Registration Status</h1>
      <h3>Hi, {registrant.nickname}!</h3>
      <div>
        <p>
          Your remaining balance is&nbsp;
          <span style={{ whiteSpace: 'nowrap' }}>
            <b>Php {Number(totalBalance).toLocaleString()}</b>
          </span>.
        </p>
        <p>
          To pay, just visit
          the Recharge Booth after the Sunday Service and present your
          Registration ID or your QR Code.
        </p>
        <div
          style={{
            maxWidth: 250,
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            paddingTop: 20,
            paddingBottom: 20,
            border: '2px solid black'
          }}
        >
          <div
            style={{
              textAlign: 'center',
              fontSize: 14,
            }}
          >
            Registration ID:&nbsp;
          </div>
          <div
            style={{
              margin: 'auto',
              background: '#f1f1f1',
              padding: 8,
              maxWidth: 100,
              textAlign: 'center',
              fontWeight: 'bold'
            }}
          >
            {registrant._id}
          </div>
          <div
            style={{
              marginTop: 20,
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            <QRCode value={registrant._id || ''} renderAs='svg' />
          </div>
        </div>
        <div style={{ display: 'none' }}>
          <svg
            id="svg-reg-img"
            style={{ 
              maxWidth: 250,
              display: 'flex',
              flexDirection: 'column',
              margin: 'auto',
              border: '2px solid black',
              height: 300,
            }}
          >
            <rect height="100%" width="100%" fill="white" />
            <text x="50%" y="40" textAnchor="middle" style={{ fontSize: 22, fontWeight: 'bold' }}>
              RECHARGE
            </text>
            <text x="50%" y="75" textAnchor="middle" style={{ fontSize: 14 }}>
              Registration ID
            </text>
            <text x="50%" y="95" textAnchor="middle" style={{ fontSize: 18 }}>
              {registrant._id} 
            </text>
            <QRCode id="xx1" y="130" x="61" value={registrant._id || ''} renderAs='svg' />
          </svg>
        </div>
      </div>
      <div style={{ margin: 'auto', marginTop: 10,  width: 200 }}>
        <Button
          variant="outlined"
          fullWidth
          component="a"
          download={`qr-${registrant.last_name}.jpg`}
          href={regImg}
        >
          DOWNLOAD QR CODE
        </Button>
      </div>
      <br />
      <h3>Registration Details</h3>
      <RegistrationDetails registrant={registrant} />
      {registrant.payments && (
        <>
          <h3>Payment Details</h3>
          <PaymentsTable hideName payments={registrant.payments} />
        </>
      )}
    </div>
  )
}

export default RegistrationStatus
