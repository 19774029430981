import React from 'react'
import Paper from '@material-ui/core/Paper'

function NameSearchResults(props) {
  const {
    matches = [],
    onItemClick = () => {}
  } = props
  
  return (
    <>
      {matches.map(match => (
        <div key={match._id.$oid} onClick={() => onItemClick(match)}>
          <Paper square style={{ padding: 12, marginBottom: 10 }}>
            <div style={{ fontWeight: 'bold' }}>
              {match.last_name},
              &nbsp;
              {match.first_name}
              &nbsp;
              {match.middle_initial}
            </div>
            <div>
              {match.contact_no}
            </div>
            <div>
              {match.email}
            </div>
          </Paper>
        </div>
      ))}
    </>
  )
}

export default NameSearchResults
