import React from 'react'

import history from 'history.js'

function AppHeader() {
  return (
    <>
      <div
        style={{
          display: 'flex',
          height: 60,
          width: 300,
          marginLeft: 'auto',
          marginRight: 'auto',
          color: '#fff',
          fontSize: 20,
          textAlign: 'center',
          letterSpacing: 3,
        }}
      >
        <div
          style={{
            margin: 'auto',
            cursor: 'pointer',
          }}
          onClick={() => history.push('/')}
        >
          <img
            alt="Recharge Logo"
            src={require('assets/recharge-logo.png')}
            style={{
              height: 40
            }}
          />
        </div>
      </div>
    </>
  )
}

export default AppHeader
