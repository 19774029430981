import React from 'react'
import { Route, Switch } from 'react-router-dom'

import routes from 'routes'

function AppContent() {
  return (
    <div
      style={{
        margin: 30,
        background: '#fff',
        borderRadius: 15,
        padding: 15,
        textAlign: 'center',
      }}
    >
      <Switch>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            render={route.component}
          />
        ))}
      </Switch>
    </div>
  )
}

export default AppContent
