import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'

import RegistrationForm from './RegistrationForm'

function RegistrationNew(props) {
  const {
    history
  } = props

  const [error, setError] = useState()

  const handleOnComplete = async (data, complete) => {
    const result = await fetch('/.netlify/functions/token-hider?collection=registrants', {
      method: 'POST',
      body: JSON.stringify({
        last_name: data.lastName.trim(),
        first_name: data.firstName.trim(),
        middle_initial: data.middleInitial.trim(),
        nickname: data.nickname.trim(),
        civil_status: data.civilStatus,
        gender: data.gender,
        age: data.age,
        contact_no: data.contactNo.trim(),
        facebook_id: data.facebookId.trim(),
        email: data.email,
        transport: data.transport,
        ministry: data.ministry,
        ice_fullname: data.iceFullname.trim(),
        ice_relationship: data.iceRelationship.trim(),
        ice_contact_no: data.iceContactNo.trim(),
        medical_conditions: data.medicalConditions.trim(),
      })
    })
    
    const registrant = await result.json()

    if (registrant['$undefined']) setError('Registration Failed. Server error occurred.')

    complete()
    history.push({
      pathname: '/register/complete',
      state: { registrant }
    })
  }

  return (
    <>
      <Typography color="error">{error}</Typography>
      <RegistrationForm onComplete={handleOnComplete} />
    </>
  )
}

export default RegistrationNew
