import React from 'react'

import AllRegistrants from './AllRegistrants'

function Admin(props) {
  const params = new URLSearchParams(props.location.search)
  const view_key = params.get('view_key')

  return (
    <>
      <AllRegistrants view_key={view_key} />
    </>
  )
}

export default Admin
