const appTheme = {
  palette: {
    primary: {
      main: '#D15134',
      contrastText: '#ffffff',
      // dark: '#024459',
    },
    secondary: {
      main: '#DE6840',
      contrastText: '#ffffff',
    },
    error: {
      main: '#b71c1c',
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
}

//appTheme.overrides = {
//  MuiCssBaseline: {
//    '@global': {
//      '@font-face': [{ fontFamily: 'inherit', font: 'inherit' }],
//    },
//  },
//}

export default appTheme
