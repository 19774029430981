import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { JsonToTable } from 'react-json-to-table'
import './index.css'

function AllRegistrants(props) {
  const { view_key } = props

  const [registrants, setRegistrants] = useState([])
  const [loading, setLoading] = useState(false)

  const getAllRegistrants = async () => {
    setLoading(true)

    const regResult = await fetch(`/.netlify/functions/token-hider?collection=registrants&view_key=${view_key}`, {
      method: 'GET',
    })

    const payResult = await fetch(`/.netlify/functions/token-hider?collection=payments&view_key=${view_key}`, {
      method: 'GET',
    })

    const registrantsJSON = await regResult.text()
    const paymentsJSON = await payResult.text()

    if (!registrantsJSON && !paymentsJSON) {
      setLoading(false)
      return
    }

    let registrants = JSON.parse(registrantsJSON)
    let payments = JSON.parse(paymentsJSON)

    registrants = registrants.sort((a, b) => {
      if (a.last_name < b.last_name)
        return -1
      if (a.last_name > b.last_name)
        return 1
      return 0
    })

    // Collect amount paid
    let paymentsByRegId = {}
    payments.forEach(payment => {
      const regId = payment.registrant._id
      if (regId in paymentsByRegId) return paymentsByRegId[regId] += parseFloat(payment.amount)

      paymentsByRegId[regId] = parseFloat(payment.amount)
    })

    const key_sorted = registrants.map(reg => {
      const sorted = {
        'Last Name': reg.last_name,
        'First Name': reg.first_name,
        'Middle Initial': reg.middle_initial,
        'Amount Paid': paymentsByRegId[reg._id] || 0,
        'Nickname': reg.nickname,
        'Gender': reg.gender,
        'Age': reg.age,
        'Civil Status': reg.civil_status,
        'Email': reg.email,
        'Contact No.': reg.contact_no,
        'Facebook': reg.facebook_id,
        'Transportation': reg.transport,
        'Medical Condition': reg.medical_conditions,
        'In Case of Emergency': reg.ice_fullname + ', ' + reg.ice_relationship + ', ' + reg.ice_contact_no,
      }

      return sorted
    })

    setRegistrants(key_sorted)
    setLoading(false)
  }

  useEffect(() => {
    getAllRegistrants()
  }, [])

  if (loading) return <CircularProgress />

  const header = Object.keys(registrants[0] || []).join(',')
  const items = registrants.map(item => Object.values(item).map(val => `"${val}"`).join(','))
  let csv = []
  csv.push(header)
  csv = csv.concat(items)

  const csvFile = new Blob([csv.join('\n')], {type: "text/csv"})

  return (
    <>
      <Button
        component="a"
        variant="outlined"
        color="primary"
        style={{ display: 'inherit', marginBottom: 10 }}
        download="recharge-registrants-list.csv"
        href={window.URL.createObjectURL(csvFile)}
      >
        EXPORT TO CSV
      </Button>
      <div style={{ fontSize: 12, textAlign: 'left', marginBottom: 10 }}>
        Items Count: {registrants.length}
      </div>
      <div style={{ fontSize: 12, textAlign: 'left', marginBottom: 10 }}>
        Total Amount Received: {registrants.reduce((acc, curr) => acc + curr['Amount Paid'], 0).toLocaleString()}
      </div>
      <div style={{ overflowX: 'auto' }}>
        <JsonToTable json={registrants} />
      </div>
    </>
  )
}

export default AllRegistrants
