import React, { useState, useEffect } from 'react'
import canvg from 'canvg'
import QRCode from 'qrcode.react'
import Button from '@material-ui/core/Button'

function RegistrationComplete(props) {
  const {
    history,
    location
  } = props

  const [regImg, setRegImg] = useState()

  const { registrant } = location.state

  // TODO Extract into a new component
  useEffect(() => {
    let canvas = document.getElementById('canvas')
    const svgString = document.getElementById('svg-reg-img').outerHTML
    canvg(canvas, svgString)

    const image = canvas.toDataURL('image/jpeg')
    setRegImg(image)
 
  }, [])

  if (!location.state) return (
    <>
      <p>Something went wrong.</p>
      <Button
        variant="outlined"
        onClick={() =>
          history.push('/')
        }
      >
        RETURN TO HOME SCREEN
      </Button>
    </>
  )

  return (
    <>
      <h1>Registration Complete!</h1>
      <p>You have successfully registered for Recharge 2020!</p>
      <div style={{ marginTop: 30 }} />
      <div
        style={{
          textAlign: 'center',
          fontSize: 14,
        }}
      >
        Registration ID:&nbsp;
      </div>
      <div
        style={{
          margin: 'auto',
          background: '#f1f1f1',
          padding: 8,
          maxWidth: 100,
          textAlign: 'center',
          fontWeight: 'bold'
        }}
      >
        {registrant._id}
      </div>
      <div
        style={{
          marginTop: 20,
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
      >
        <QRCode value={registrant._id || ''} renderAs='svg' />
      </div>
      <div style={{ display: 'none' }}>
        <canvas id="canvas" width="250px" height="300px"/>
        <svg
          id="svg-reg-img"
          style={{ 
            maxWidth: 250,
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            border: '2px solid black',
            height: 300,
          }}
        >
          <rect height="100%" width="100%" fill="white" />
          <text x="50%" y="40" textAnchor="middle" style={{ fontSize: 22, fontWeight: 'bold' }}>
            RECHARGE
          </text>
          <text x="50%" y="75" textAnchor="middle" style={{ fontSize: 14 }}>
            Registration ID
          </text>
          <text x="50%" y="95" textAnchor="middle" style={{ fontSize: 18 }}>
            {registrant._id} 
          </text>
          <QRCode id="xx1" y="130" x="61" value={registrant._id || ''} renderAs='svg' />
        </svg>
      </div>
      <div style={{ margin: 'auto', marginTop: 10,  width: 200 }}>
        <Button
          variant="outlined"
          fullWidth
          component="a"
          download={`qr-${registrant.last_name}.jpg`}
          href={regImg}
        >
          DOWNLOAD QR CODE
        </Button>
      </div>
      <div style={{ marginTop: 40 }} />
      <p style={{ fontSize: 14 }}>
        Present your Registration ID or your QR Code when making payments
        at the Recharge Booth after the Sunday Services.
      </p>
      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          history.push({
            pathname: '/status',
            state: { registrant }
          })
        }
      >
        SEE REGISTRATION STATUS
      </Button>
    </>
  )
}

export default RegistrationComplete
