import React from 'react'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'

function Home(props) {
  const {
    history
  } = props

  return (
    <>
      <h1>Recharge Online Registration</h1>
      <Button
        variant="contained"
        fullWidth
        color="primary"
        style={{ 
          fontFamily: 'inherit',
          maxWidth: 400,
        }}
        onClick={() => history.push('/register')}
      >
        Register Now
      </Button>
      <div style={{ paddingTop: 25 }}>
        <span>Already registered? </span>
        <Link
          component="button"
          style={{
            fontFamily: 'inherit',
            verticalAlign: 'baseline',
            fontSize: 16,
            fontWeight: 'bold',
            color: '#DE6840'
          }}
          onClick={() => history.push('/find')}
        >
          Check status here.
        </Link>
      </div>
    </>
  )
}

export default Home
