import React, { useState, useReducer } from 'react'
import Button from '@material-ui/core/Button'

import PaymentDialog from './PaymentDialog'
import PaymentsTable from './PaymentsTable'
import usePayments from 'components/Payments/usePayments'

function Dashboard(props) {
  const {
    history,
    location
  } = props

  const [dialogOpen, setDialogOpen] = useState(false)
  const [callTrigger, forceUpdate] = useReducer(x => x + 1, 0)

  const { receiver } = location.state || {}

  const { payments, notFound, fetching } = usePayments(receiver._id || 'dud', callTrigger)

  if (!location.state) return (
    <>
      <p>Something went wrong.</p>
      <Button
        variant="outlined"
        onClick={() =>
          history.goBack()
        }
      >
        GO BACK
      </Button>
    </>
  )
  
  const handleClickOpen = () => {
    setDialogOpen(true)
  }

  const handleClose = value => {
    setDialogOpen(false)
  }

  return (
    <>
      <div style={{ textAlign: 'initial' }}>
        <h4 align="left">
          {receiver.name}
        </h4>
        <div style={{ display: 'flex' }}>
          <Button
            align="right"
            variant="contained"
            color="primary"
            onClick={() => handleClickOpen(true)}
          >
            RECEIVE PAYMENT
          </Button>
        </div>
        <PaymentDialog
          open={dialogOpen}
          onClose={handleClose}
          receiver={receiver}
          onSuccessfulPayment={() => forceUpdate()}
        />
        <h2>Processed Payments</h2>
        {fetching 
          ? <p>Fetching...</p>
          : <div style={{ maxWidth: 600 }}><PaymentsTable payments={payments} /></div>
        }
      </div>
    </>
  )
}

export default Dashboard
