import React, { useState } from 'react'

import RegistrationFinder from './RegistrationFinder'

function RegistrationFind(props) {
  const {
    history
  } = props

  const [showHeader, setShowHeader] = useState(true)

  return (
    <>
      {showHeader && (
        <>
          <h1>Status Checker</h1>
        </>
      )}
      <div style={{ height: 20 }} />
      <RegistrationFinder
        hideOtherOpts
        onOptionClick={() => setShowHeader(false)}
        onBackClick={() => setShowHeader(true)}
        callback={registrant => {
          if (!registrant) return

          history.push({
            pathname: '/status',
            state: { registrant }
          })
        }}
      />
      <div style={{ height: 21.440 }} />
    </>
  )
}

export default RegistrationFind
