import React from 'react'
import Home from './scenes/Home'
import Registration from './scenes/Registration'
import RegistrationComplete from './scenes/Registration/RegistrationComplete'
import RegistrationFind from './scenes/Registration/RegistrationFind'
import RegistrationStatus from './scenes/Registration/RegistrationStatus'
import Admin from './scenes/Admin'
import Receiver from './scenes/Receiver'
import ReceiverDashboard from './scenes/Receiver/Dashboard'

const routes = [
  { 
    path: '/', 
    component: (props) => <Home {...props} />, 
    exact: true,
  },
  { 
    path: '/register', 
    component: (props) => <Registration {...props} />, 
    exact: true,
  },
  { 
    path: '/register/complete', 
    component: (props) => <RegistrationComplete {...props} />, 
    exact: true,
  },
  { 
    path: '/find', 
    component: (props) => <RegistrationFind {...props} />, 
    exact: true,
  },
  { 
    path: '/status', 
    component: (props) => <RegistrationStatus {...props} />, 
    exact: true,
  },
  { 
    path: '/admin', 
    component: (props) => <Admin {...props} />, 
    exact: true,
  },
  { 
    path: '/rcvr', 
    component: (props) => <Receiver {...props} />, 
    exact: true,
  },
  { 
    path: '/rcvr/:id', 
    component: (props) => <ReceiverDashboard {...props} />, 
    exact: true,
  },
  {
    component: () => <div>404 Not Found</div>,
  },
]

export default routes
