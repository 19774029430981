import React from 'react'

import RegistrationNew from './RegistrationNew'

function Registration(props) {
  return (
    <>
      <h1>Register</h1>
      <RegistrationNew {...props} />
    </>
  )
}

export default Registration