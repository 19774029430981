import React from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

function PaymentsTable(props) {
  const {
    payments = [],
    hideName = false,
  } = props
  
  const mongoDateToString = mongoDate => {
    if (!mongoDate.$date) {
      console.log('No $date')
      return null
    }

    const UNIX_timestamp = mongoDate.$date.$numberLong.slice(0, -3)
    const a = new Date(UNIX_timestamp * 1000)
    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    const year = a.getFullYear()
    const month = months[a.getMonth()]
    const date = a.getDate()
    let hour = a.getHours()
    const period = hour < 12 ? 'AM' : 'PM'
    hour = hour < 12 ? hour : hour - 12
    hour = hour === 0 ? 12 : hour
    const min = a.getMinutes()
    const sec = a.getSeconds()
    const datestring = date + ' ' + month + ' ' + year
    const pad = input => String(input).padStart(2, '0')
    const timestring = pad(hour) + ':' + pad(min) + ':' + pad(sec) + ' ' + period

    return datestring + '  ' + timestring
  }

  const Item = ({ payment }) => (
    <Paper square style={{ padding: 12, marginBottom: 10 }}>
      <Grid container>
        <Grid item sm={8} xs={7} style={{ color: '#30425a' }}>
          {!hideName && (
            <div style={{ fontWeight: 'bold' }}>
              {payment.registrant.last_name},
              &nbsp;
              {payment.registrant.first_name}
              &nbsp;
              {payment.registrant.middle_initial}
            </div>
          )}
          <div>
            Receipt Number: {payment.receipt_no}
          </div>
          <div style={{ marginTop: 5, fontSize: 12 }}>
            {mongoDateToString(payment.created_at)}
          </div>
        </Grid>
        <Grid item sm={4} xs={5} style={{ textAlign: 'right', color: '#ff5a5f' }}>
          <span style={{ fontSize: 16 }}>Php</span>
          <span style={{ fontSize: 22, fontWeight: 'bold' }}> {payment.amount}</span>
        </Grid>
      </Grid>
    </Paper>
  )

  if (!payments.length) return (
    <div>None</div>
  )

  return (
    <>
      {payments.map(payment => (
        <div key={payment._id.$oid}>
          <Item payment={payment} />
        </div>
      ))}
    </>
  )
}

export default PaymentsTable
