import React, { useState, useEffect, useRef } from 'react'

function usePayments(receiver, trigger) {
  const isMounted = useRef(null)

  const [payments, setPayments] = useState([])
  const [fetching, setFetching] = useState(false)
  const [notFound, setNotFound] = useState(false)


  useEffect(() => {
    isMounted.current = true
    getPayments(receiver)

    return () => isMounted.current = false
  }, [receiver, trigger])

  const getPayments = async receiver => {
    setFetching(true)
    const result = await fetch(`/.netlify/functions/token-hider?collection=payments&receiver=${receiver}`, {
      method: 'GET',
    })
    
    const payments = await result.json()

    if (!payments) {
      setNotFound(true)
      setFetching(false)
      return
    }

    setPayments(payments)
    setNotFound(false)
    setFetching(false)
  }

  return { payments, notFound, fetching }
}


export default usePayments
