import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import GoIcon from '@material-ui/icons/ArrowForward'
import QrReader from 'react-qr-reader'

function RegistrationFinder(props) {
  const {
    callback,
    showUploadQR = true,
    onOptionClick = () => {},
    onBackClick = () => {},
  } = props

  const [didSearch, setDidSearch] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [hideOtherOpts, setHideOtherOpts] = useState(props.hideOtherOpts)
  const [QRScan, setQRScan] = useState(false)
  const [QRLM, setQRLM] = useState(false)
  const [scanStop, setScanStop] = useState(false)
  const [code, setCode] = useState('')

  let reader = React.createRef()

  useEffect(() => {
    if (QRLM) {
      reader.current.openImageDialog()
    }
  }, [QRLM])

  const handleGo = async (successCallback, code) => {
    setDidSearch(true)
    const result = await fetch(`/.netlify/functions/token-hider?collection=registrants&code=${code}`, {
      method: 'GET',
    })
    
    const registrant = await result.json()

    if (!registrant) {
      setNotFound(true)
      setDidSearch(false)
      return
    }

    successCallback(registrant)
  }

  return (
    <>
      <div>
        {didSearch ? 'Searching...' : null}
        {notFound ? 'Not Found' : null}
      </div>
      {!QRScan ? (
        <>
          {showUploadQR && (
            <>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    onOptionClick()
                    setQRScan(true)
                    setQRLM(true)
                  }}
                >
                  Upload QR
                </Button>
              </div>
              <div style={{ height: 30 }} />
            </>
          )}
          {props.hideOtherOpts && 1 ==2 && (
            <div style={{ width: 300, display: 'inline-block' }}>
              <div style={{ height: 15 }} />
              <Switch
                checked={!hideOtherOpts}
                onChange={() => setHideOtherOpts(prevState => !prevState)}
                value="checkedB"
                color="primary"
              />
              <span>Show other options</span>
              <div style={{ height: 10 }} />
            </div>
          )}
          {!hideOtherOpts && (
            <>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    onOptionClick()
                    setQRScan(true)
                    setQRLM(false)
                  }}
                >
                  Scan QR
                </Button>
              </div>
              <div style={{ height: 30 }} />
              <div>
                <TextField
                  label="Registration ID"
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      fontFamily: 'inherit'
                    }
                  }}
                  value={code}
                  onChange={e => {
                    setNotFound(false)
                    setCode(e.target.value.trim())
                  }}
                  onKeyPress={e => {
                    if(e.key === 'Enter'){
                      handleGo(callback, code)
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton disabled={!code} onClick={() => handleGo(callback, code)}>
                          <GoIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </>
          )}
          {props.hideOtherOpts && (
            <>
              <div style={{ height: 20 }} />
              <Button
                //variant="outlined"
                size="small"
                color="primary"
                onClick={() => setHideOtherOpts(prevState => !prevState)}
              >
                {hideOtherOpts ? 'Show more options' : 'Hide'}
              </Button>
            </>
          )}
        </>
      )
      : (
        <div style={{ minWidth: 230, maxWidth: 400, margin: 'auto', textAlign: 'left' }}>
          <Button
            variant="outlined"
            onClick={() => {
              onBackClick()
              setQRScan(false)
              setQRLM(false)
            }}
            size="small"
          >
            Go Back
          </Button>
          <div style={{ height: 10 }} />
          <QrReader
            ref={reader}
            delay={300}
            onError={err => console.log(err)}
            onScan={async data => {
              if (data && !scanStop) {
                setScanStop(true)
                await handleGo(callback, data)
              }
            }}
            legacyMode={QRLM}
            style={{ width: '100%' }}
          />
          <div style={{ height: 30 }} />
        </div>
      )}
    </>
  )
}

export default RegistrationFinder
