import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import GoIcon from '@material-ui/icons/ArrowForward'
import QrReader from 'react-qr-reader'

function Receiver(props) {
  const {
    history,
    callback,
    showUploadQR = true,
    onOptionClick = () => {},
    onBackClick = () => {},
  } = props

  const [didSearch, setDidSearch] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [QRScan, setQRScan] = useState(false)
  const [QRLM, setQRLM] = useState(false)
  const [scanStop, setScanStop] = useState(false)
  const [code, setCode] = useState('')

  let reader = React.createRef()

  useEffect(() => {
    if (QRLM) {
      reader.current.openImageDialog()
    }
  }, [QRLM])

  const handleGo = async (successCallback, code) => {
    setDidSearch(true)
    const result = await fetch(`/.netlify/functions/token-hider?collection=receivers&code=${code}`, {
      method: 'GET',
    })
    
    const receiver = await result.json()

    if (!receiver) {
      setNotFound(true)
      setDidSearch(false)
      return
    }

    history.push({
      pathname: `/rcvr/${receiver.alias}`,
      state: { receiver }
    })
    //successCallback(registrant)
  }

  return (
    <>
      <div>
        {didSearch ? 'Logging In...' : null}
        {notFound ? 'User Not Found' : null}
      </div>
      {!QRScan ? (
        <>
          <h1>Payment Receiver</h1>
          <div style={{ marginTop: 30 }}>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  onOptionClick()
                  setQRScan(true)
                  setQRLM(false)
                }}
              >
                Log In
              </Button>
            </div>
            {showUploadQR && (
              <>
                <div style={{ height: 50 }} />
                <div>
                  <div style={{ fontSize: 13 }}>Log In with QR Image</div>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => {
                      onOptionClick()
                      setQRScan(true)
                      setQRLM(true)
                    }}
                  >
                    Upload QR Image
                  </Button>
                </div>
              </>
            )}
          </div>
          <div style={{ height: 70 }} />
          <div style={{ fontSize: 13 }}>
            If the options above don't work, log in using your ID.
          </div>
          <TextField
            label="ID"
            InputLabelProps={{
              shrink: true,
              style: {
                fontFamily: 'inherit'
              }
            }}
            value={code}
            onChange={e => {
              setNotFound(false)
              setCode(e.target.value.trim())
            }}
            onKeyPress={e => {
              if(e.key === 'Enter'){
                handleGo(callback, code)
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton disabled={!code} onClick={() => handleGo(callback, code)}>
                    <GoIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </>
      )
      : (
        <div style={{ minWidth: 230, maxWidth: 400, margin: 'auto', textAlign: 'left' }}>
          <Button
            variant="outlined"
            onClick={() => {
              onBackClick()
              setQRScan(false)
              setQRLM(false)
            }}
            size="small"
          >
            Go Back
          </Button>
          <div style={{ height: 10 }} />
          <QrReader
            ref={reader}
            delay={300}
            onError={err => console.log(err)}
            onScan={async data => {
              if (data && !scanStop) {
                setScanStop(true)
                await handleGo(callback, data)
              }
            }}
            legacyMode={QRLM}
            style={{ width: '100%' }}
          />
          <div style={{ height: 30 }} />
        </div>
      )}
    </>
  )
}

export default Receiver

//import React, { useState } from 'react'
//import TextField from '@material-ui/core/TextField'
//import InputAdornment from '@material-ui/core/InputAdornment'
//import IconButton from '@material-ui/core/IconButton'
//import GoIcon from '@material-ui/icons/ArrowForward'
//
//function Receiver(props) {
//  const {
//    history
//  } = props
//  
//  const [fetching, setFetching] = useState(false)
//  const [notFound, setNotFound] = useState(false)
//  const [credential, setCredential] = useState('')
//
//  const handleLogin = async () => {
//    setFetching(true)
//    const result = await fetch(`/.netlify/functions/token-hider?collection=receivers&code=${credential}`, {
//      method: 'GET',
//    })
//    
//    const receiver = await result.json()
//
//    if (!receiver) {
//      setNotFound(true)
//      setFetching(false)
//      return
//    }
//
//    history.push({
//      pathname: `/rcvr/${receiver.alias}`,
//      state: { receiver }
//    })
//  }
//
//  return (
//    <>
//      <TextField
//        label="ID"
//        InputLabelProps={{
//          shrink: true,
//          style: {
//            fontFamily: 'inherit'
//          }
//        }}
//        value={credential}
//        onChange={e => {
//          setNotFound(false)
//          setCredential(e.target.value)
//        }}
//        onKeyPress={e => {
//          if(e.key === 'Enter'){
//            handleLogin(e)
//          }
//        }}
//        InputProps={{
//          endAdornment: (
//            <InputAdornment position="end">
//              <IconButton onClick={handleLogin}>
//                <GoIcon />
//              </IconButton>
//            </InputAdornment>
//          ),
//        }}
//      />
//      <div>
//        {fetching ? 'Authenticating...' : null}
//        {notFound ? 'Not Found' : null}
//      </div>
//    </>
//  )
//}
//
//
//export default Receiver
